/* GT-Walsheim-Regular */
@font-face {
  font-family: 'GT-Walsheim-Regular';
  font-style: normal;
  font-weight: 400;
  src: local('GT-Walsheim Regular'), local('GT-Walsheim-Regular'),
    url('./assets/fonts/GT-Walsheim-Regular.woff2') format('woff2'),
    url('./assets/fonts/GT-Walsheim-Regular.woff') format('woff'),
    url('./assets/fonts/GT-Walsheim-Regular.ttf') format('truetype');
  font-display: swap;
}

/* GT-Walsheim-Regular-Oblique */
@font-face {
  font-family: 'GT-Walsheim-Regular-Oblique';
  font-style: oblique;
  font-weight: 400;
  src: local('GT-Walsheim Regular Oblique'),
    local('GT-Walsheim-Regular-Oblique'),
    url('./assets/fonts/GT-Walsheim-Regular-Oblique.woff2') format('woff2'),
    url('./assets/fonts/GT-Walsheim-Regular-Oblique.woff') format('woff'),
    url('./assets/fonts/GT-Walsheim-Regular-Oblique.ttf') format('truetype');
  font-display: swap;
}

/* GT-Walsheim-Medium */
@font-face {
  font-family: 'GT-Walsheim-Medium';
  font-style: normal;
  font-weight: 500;
  src: local('GT-Walsheim Medium'), local('GT-Walsheim-Medium'),
    url('./assets/fonts/GT-Walsheim-Medium.woff2') format('woff2'),
    url('./assets/fonts/GT-Walsheim-Medium.woff') format('woff'),
    url('./assets/fonts/GT-Walsheim-Medium.ttf') format('truetype');
  font-display: swap;
}

/* GT-Walsheim-Medium-Oblique */
@font-face {
  font-family: 'GT-Walsheim-Medium-Oblique';
  font-style: oblique;
  font-weight: 500;
  src: local('GT-Walsheim Medium Oblique'), local('GT-Walsheim-Medium-Oblique'),
    url('./assets/fonts/GT-Walsheim-Medium-Oblique.woff2') format('woff2'),
    url('./assets/fonts/GT-Walsheim-Medium-Oblique.woff') format('woff'),
    url('./assets/fonts/GT-Walsheim-Medium-Oblique.ttf') format('truetype');
  font-display: swap;
}

/* GT-Walsheim-Bold */
@font-face {
  font-family: 'GT-Walsheim-Bold';
  font-style: normal;
  font-weight: 700;
  src: local('GT-Walsheim Bold'), local('GT-Walsheim-Bold'),
    url('./assets/fonts/GT-Walsheim-Bold.woff2') format('woff2'),
    url('./assets/fonts/GT-Walsheim-Bold.woff') format('woff'),
    url('./assets/fonts/GT-Walsheim-Bold.ttf') format('truetype');
  font-display: swap;
}

/* GT-Walsheim-Bold-Oblique */
@font-face {
  font-family: 'GT-Walsheim-Bold-Oblique';
  font-style: oblique;
  font-weight: 700;
  src: local('GT-Walsheim Bold Oblique'), local('GT-Walsheim-Bold-Oblique'),
    url('./assets/fonts/GT-Walsheim-Bold-Oblique.woff2') format('woff2'),
    url('./assets/fonts/GT-Walsheim-Bold-Oblique.woff') format('woff'),
    url('./assets/fonts/GT-Walsheim-Bold-Oblique.ttf') format('truetype');
  font-display: swap;
}
