.adyen-checkout__threeds2__challenge {
  background: #f7f8f9 !important;
  border-radius: 12px !important;
  margin: auto !important;
  width: auto !important;
}

.adyen-checkout__iframe {
  width: 100% !important;
}

.adyen-checkout__applepay__button {
  -webkit-appearance: -apple-pay-button !important;
}
